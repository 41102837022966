<template>
  <div class="culture-container">
    <div class="paragraph">
      918.com品牌文化主要以“客户至上”、“卓越创新”、“诚信、
      负责”为核心价值观，旨在打造全球娱乐性平台；
      “客户至上“是918.com娱乐平台品牌文化的核心价值观，
      致力于为客户提供优质的娱乐体验，不断改进和提高服务质量；
      918.com娱乐强调“卓越创新”，不断推出新的娱乐体验、
      新的服务，以满足不同客户的需求； “诚信、负责”
      是918.com娱乐平台品牌文化的重要组成部分，平台注重诚信
      经营，确保娱乐体验的公平公正，为客户提供安全 可靠的娱乐环境。
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.culture-container {
  background: url("./img/culture-bg.png") no-repeat 0;
  height: 92vh;
  background-size: cover;
  background-size: cover;
  background-position: 0px 15vw;
  .paragraph {
    color: var(--black);
    text-align: center;
    padding: 20px;
    font-size: var(--font-medium);
    font-weight: bold;
    line-height: 30px;
  }
}
</style>
