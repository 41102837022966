<template>
  <div class="gift-container">
    <div class="gift">
      <img src="./imgs/gift.png" alt="" />
    </div>
    <div class="btn">
      <a href="https://biaodan100.com/q/lfokyi">领取礼品</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.gift-container {
  background: url("./imgs/gift-bg.png") no-repeat 0;
  background-size: cover;
  padding: 5px;
  @media screen and (min-height: 820px) {
    height: 92vh;
  }
  @media screen and (min-width: 700px) {
    height: 100%;
  }
  .gift {
    img {
      margin-top: 10px;
      width: 100%;
    }
  }
  .btn {
    padding: 5px;
    padding-bottom: 20px;
    a {
      height: 45px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--brown);
      box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      font-weight: bold;
    }
  }
}
</style>
