import state from "./state";

export default {
  SET_LIVECHATADDRESS: (state, val) => {
    state.liveChatAddress = val;
  },
  SET_LIVECHATADDRESSOCSS: (state, val) => {
    state.liveChatAddressOCSS = val;
  },
  SET_LIVECHATOCSSMODE: (state, val) => {
    state.liveChatOCSSMode = val;
  },
  SET_TOKEN: (state, val) => {
    state.token = val;
  },
  SET_U2TOKEN: (state, val) => {
    state.u2token = val;
  },
  SET_SHOWDETAIL: (state, val) => {
    state.showDetail = val;
  },
};
