<template>
  <TPDefault />
</template>
<script>
import { onMounted } from "@vue/runtime-core";
import business from "./store/business";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import TPDefault from "./views/TPDefault.vue";
export default {
  setup() {
    const backToTop = () => {
      window.scrollTo(0, 0);
    };
    const store = useStore();
    store.dispatch("getWebToken");

    const dispatch = () => {
      business.reStoreOCSSscript();
    };
    const liveChatOCSSMode = ref(
      computed(() => store.state.liveChatAddressOCSS.state)
    );
    onMounted(() => {
      dispatch();
    });
    const goOnlineCS = () => {
      if (liveChatOCSSMode.value) {
        cskefu.openChatDialog();
      }
    };
    return { backToTop, goOnlineCS };
  },
  components: {
    TPDefault,
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#ukefu-point {
  display: none !important;
}
</style>
