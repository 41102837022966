export default {
  liveChatAddressOCSS: {
    propertyStr: "",
    url: "",
    state: false,
  },
  token: "",
  u2token: "",
  showDetail: false,
};
