export default {
  // 接口相关配置
  apiUrl: 'http://a01-brandsite-h5.k8s-fat.com/',
  PUBLIC_KEY:
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDSu2AZPdT2Fqpqxctx3EbnRuuYdBxFZDYG7MASIgw/DFl3P9FAp7S9WaQjdM1NmgBDgvfUWx1xj72LNz4EP4Euh9EESKceNCeoE4M8ZP4ENUQX0nDMbpmIG3/JCI8B5Iv2FKj2q0gGbE0WsLdrYDzFXTYbZKRJSbMMjHT3HtKD/wIDAQAB",
  APP_ID: "668c71b1446cf9aa7402qXF9EFKDMDVr",
  PRODUCT_ID: "1682d3a2ee0c4ee8acbe58a5c39bb888",
  HEADER_V: "1.0.0",
  apiNameSpace: "_glaxy_1e3c3b_",
};
