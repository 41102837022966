import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import { Tab, Tabs, IndexBar, IndexAnchor, Swipe, SwipeItem } from "vant";
import "vant/lib/index.css";

createApp(App)
  .use(store)
  .use(router)
  .use(Tab)
  .use(Tabs)
  .use(IndexBar)
  .use(IndexAnchor)
  .use(Swipe)
  .use(SwipeItem)
  .mount("#app");
