<template>
  <div class="detail-container">
    <div class="header">
      <img src="./img/arrow.svg" alt="" @click="goBack" />
      <div class="title">{{ img.title }}</div>
    </div>
    <div class="content">
      <p>{{ newDate }}</p>
      <img :src="img.url" @load="isLoaded = false" hidden />
      <transition name="show">
        <div class="card-content" v-show="!isLoaded">
          <img :src="img.url" alt="" />
          <div class="paragraph">
            {{ img.description }}
          </div>
        </div>
      </transition>
      <div class="skeleton" v-show="isLoaded">
        <div class="img-skel"></div>
        <div class="para-skel">
          <div class="p3"></div>
          <div class="p2"></div>
          <div class="p1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { computed, ref, onMounted } = require("@vue/runtime-core");
const { useStore } = require("vuex");
import Loader from "@/components/loader/index.vue";

//refs
const props = defineProps(["img"]);
const store = useStore();
const img = ref(props.img);
const isLoaded = ref(false);
const newDate = ref();
//created
isLoaded.value = true;

//mounts

onMounted(() => {
  const date = new Date(img.value.date * 1000);
  const min =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const sec =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  newDate.value =
    date.getFullYear() +
    "年" +
    (date.getMonth() + 1) +
    "月" +
    date.getDate() +
    "日 " +
    hour +
    ":" +
    min +
    ":" +
    sec;
});
//methods
const goBack = () => {
  store.commit("SET_SHOWDETAIL", false);
};
</script>

<style lang="scss" scoped>
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
.detail-container {
  height: 100vh;
  position: absolute;
  top: 0;
  background: #f2f4f6;
  z-index: 9999;
  .header {
    padding: 15px 10px;
    background: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
    justify-content: center;
    img {
      width: 25px;
      position: absolute;
      top: 5vw;
      left: 10px;
    }
    .title {
      place-self: center;
      color: var(--brown);
      font-weight: bold;
    }
  }
  .content {
    padding: 0 10px;
    p {
      font-size: var(--font-medium);
      margin: 10px 0;
    }
    .img-skel {
      width: 94vw;
      background: rgb(27, 8, 8);
      background: linear-gradient(110deg, #e0e0e0 8%, #e9e9e9 18%, #e0e0e0 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      height: 25vh;
    }
    .para-skel {
      width: 94vw;
      height: 25vh;
      margin-top: 10px;

      .p3 {
        width: 100%;
        background: #eee;
        background: linear-gradient(
          110deg,
          #e0e0e0 8%,
          #e9e9e9 18%,
          #e0e0e0 33%
        );

        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
        height: 4vh;
      }
      .p2 {
        width: 75%;
        background: #eee;
        background: linear-gradient(
          110deg,
          #e0e0e0 8%,
          #e9e9e9 18%,
          #e0e0e0 33%
        );

        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
        height: 4vh;
        margin: 20px 0;
      }
      .p1 {
        width: 50%;
        background: #eee;
        background: linear-gradient(
          110deg,
          #e0e0e0 8%,
          #e9e9e9 18%,
          #e0e0e0 33%
        );

        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
        height: 4vh;
      }
    }
    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .paragraph {
      margin: 10px 0;
      font-size: var(--font-medium);
      line-height: 24px;
      color: #151515;
      font-weight: bolder;
    }
  }
}
</style>
