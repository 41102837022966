<template>
  <transition name="fadeLeft">
    <Detail v-if="showDetail" :img="image" />
  </transition>
  <div class="topic_container" v-if="!showDetail">
    <van-index-bar :sticky="true" :index-list="indexList">
      <div
        class="group-index"
        v-for="(topic, index) in topic_data"
        :key="index"
      >
        <van-index-anchor :index="topic.year" style="font-size: 24px" />
        <div class="cards">
          <div
            class="card"
            v-for="(image, index) in topic.slides"
            :key="index"
            @click="goToDetail(image)"
          >
            <img :src="image.url" alt="" class="img" />
            <div class="title">{{ image.title }}</div>
            <div class="filter"></div>
          </div>
        </div>
      </div>
    </van-index-bar>
    <div class="line"></div>
  </div>
</template>

<script setup>
const { ref } = require("@vue/reactivity");
const { onMounted, computed } = require("@vue/runtime-core");
import axios from "axios";
import { useStore } from "vuex";
import Detail from "../../components/detail/index.vue";
//refs
const topic_data = ref();
const indexList = ref([]);
const store = useStore();
const image = ref();
const images = ref([]);
//mounts
onMounted(() => {
  getImag();
});
//computed
const showDetail = computed(() => store.state.showDetail);

const getImag = () => {
  const wmsPath =
    location.origin.includes("a01-brandsite-h5.k8s-fat.com") ||
    location.origin.includes("localhost")
      ? "_t"
      : "_l";
  new Promise((resolve, reject) => {
    axios
      .get(
        `/cdn/1e3c3bMB/externals/static/_wms/${wmsPath}/_data/_promo/topic_data.txt?v=` +
          Math.floor(Date.now() / 1000)
      )
      .then((data) => {
        const _topic_data = data.data
          .map((item) => {
            const final_data = {
              title: item.title,
              sub_title: item.label,
              url:
                item.pic.replace("1e3c3bFM", "A01FM") +
                "?r=" +
                Math.floor(Date.now() / 1000),
              modal_pic: item.pic,
              index: item.index,
              date: item.time_begin,
              description: item.describe,
            };
            return final_data;
          })
          .sort((a, b) => a.index - b.index);

        const grouped = _topic_data.reduce((group, product) => {
          const { date } = product;
          const _date = new Date(date * 1000);
          const year = _date.getFullYear();
          group[year] = group[year] ?? [];
          group[year].push(product);
          return group;
        }, {});
        let final = [];
        Object.keys(grouped).forEach(function (key) {
          grouped[key] = grouped[key].sort((a, b) => {
            return a.index - b.index;
          });
          final.push({
            year: key,
            slides: grouped[key],
          });
        });
        final.forEach((item) => {
          item.slides.sort((a, b) => {
            return b.date - a.date;
          });
        });
        topic_data.value = final.sort((a, b) => {
          return b.year - a.year;
        });
      });
  });
};
//methods
const goToDetail = (img) => {
  image.value = img;
  store.commit("SET_SHOWDETAIL", true);
};
</script>

<style lang="scss" scoped>
.topic_container {
  padding: 10px 0;
  position: relative;
  .line {
    width: 1px;
    height: 98%;
    position: absolute;
    bottom: 20px;
    left: 9.5vw;
    z-index: -9;
    background: #e4e7eb;
  }
}

::v-deep {
  .van-index-anchor {
    color: var(--brown);
    font-size: var(--font-large);
    width: 18vw;
    padding: 0;
    text-align: center;
    float: left;
    background: red;
    background: #f2f4f6;
  }
  .van-index-bar__sidebar {
    display: none;
  }
  .van-index-anchor--sticky {
    width: 17vw !important;
    padding: 0;
    text-align: center;
    background: #f2f4f6;
    left: 0 !important;
    font-size: 18px;
    &::after {
      display: none;
    }
  }
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
  padding: 10px 10px 10px 10px;
  // &::before {
  //   content: "";
  //   background: #e4e7eb;
  //   height: 100%;
  //   width: 1px;
  //   position: absolute;
  //   bottom: 0;
  //   left: -13.5%;
  //   z-index: -9;
  // }

  .card {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    width: 80vw;
    .img {
      place-self: center;
      height: 150px;
      width: 100%;
      object-fit: cover;
      line-height: 0%;
      display: block;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .filter {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: 0.3s;
        height: 150px;
        width: 100%;
        position: absolute;
        content: "";
        background: #1b47a9;
        opacity: 0.4;
        z-index: 2;
      }
    }

    .title {
      padding: 15px;
      background: #fff;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 10px;
      color: var(--brown);
      font-size: var(--font-large);
      font-weight: 700;
      box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
