<template>
  <div class="fullnav-container">
    <div class="nav-head">
      <div class="logo">
        <img
          src="../imgs/logo(1).svg"
          alt=""
          @click="$router.push('/'), $emit('back')"
        />
      </div>
      <div class="title">导航</div>
      <div class="close">
        <img
          src="../imgs/close.svg"
          alt=""
          @click="back"
          id="close" 
        />
      </div>
    </div>
    <div class="nav-links">
      <div class="links">
        <div
          class="link"
          v-for="(link, index) in links"
          :key="index"
          @click="goTo(link.tab, link.to)"
          :class="{ active: active_tab == link.to }"
        >
          {{ link.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//imports
const { default: router } = require("@/router");
const { ref } = require("@vue/reactivity");
const { onMounted, computed } = require("@vue/runtime-core");
const emit = defineEmits(["back"]);
//refs
const links = ref([
  {
    title: "品牌资讯",
    to: "/",
    tab: 1,
  },
  {
    title: "品牌故事",
    to: "/story",
    tab: 2,
  },
  {
    title: "品牌文化",
    to: "/culture",
    tab: 3,
  },
  {
    title: "品牌专题",
    to: "/topic",
    tab: 4,
  },
  {
    title: "礼品领取",
    to: "/gift",
    tab: 2,
  },
  // {
  //   title: "品牌手册",
  //   to: "/manual",
  //   tab: 5,
  // },
]);
const isAnimate = ref(false);

const active_tab = ref(computed(() => router.currentRoute.value.fullPath));
//mounts
onMounted(() => {
  console.log(isAnimate.value);
});
//method
const back = () => {
  const close = document.getElementById("close"); 
  close.classList.add("rotate")
  setTimeout(()=> {
    console.log('asdf')
    close.classList.remove('rotate')
  },300)
  emit("back")
}; 
const goTo = (tab, link) => {
  console.log(link);
  active_tab.value = tab;
  router.push(link);
  emit("back");
};
</script>

<style lang="scss" scoped>
.fullnav-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  .nav-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .logo {
      img {
        width: 90px;
      }
    }
    .title {
      color: var(--brown);
      font-size: var(--font-xlarge);
      font-weight: bold;
    }
    .close {
      width: 100px;
      display: flex;
      justify-content: end;
      img {
        width: 25px;
        transition: 0.3s;
      }
      .rotate {
        transform: rotate(270deg);
      }
    }
  }
  .nav-links {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    .links {
      :not(:first-child) {
        margin: 40px 0px;
      }
      color: var(--gray);
      font-size: var(--font-large);
      text-align: center;
      .link {
        transition: 0.3s;
      }
      .active {
        transition: 0.3s;
        background-color: var(--bg-gray);
        color: var(--brown);
        border-radius: 50px;
        height: 42.5px;
        width: 145px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
