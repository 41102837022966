import { createRouter, createWebHistory } from "vue-router";
import News from "../views/news/index.vue";
import Story from "../views/story/index.vue";
import Gift from "../views/gift/index.vue";
import Topic from "../views/topic/index.vue";
import Manual from "../views/manual/index.vue";
import Culture from "../views/culture/index.vue";
// function lazyLoad(view) {
//   return () => import(`@/views/${view}.vue`);
// }
const routes = [
  {
    path: "/",
    name: "品牌资讯",

    component: News,
  },
  {
    path: "/gift",
    name: "礼品领取",
    component: Gift,
  },
  {
    path: "/story",
    name: "品牌故事",
    component: Story,
  },
  {
    path: "/manual",
    name: "品牌手册",
    component: Manual,
  },
  {
    path: "/topic",
    name: "品牌专题",
    component: Topic,
  },
  {
    path: "/culture",
    name: "品牌文化",
    component: Culture,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
    component: News,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
