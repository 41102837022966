<template>
  <transition name="fadeLeft">
    <Detail v-if="showDetail" :img="image" />
  </transition>
  <div class="news-container" v-if="!showDetail">
    <div class="btn-group">
      <div
        class="btn"
        :class="{ active: isBasic, notActive: !isBasic }"
        @click="changeTab('basic_info', true)"
      >
        旅游资讯
      </div>
      <div
        class="btn"
        :class="{ active: !isBasic, notActive: isBasic }"
        @click="changeTab('exclusive_info', false)"
      >
        专属资讯
      </div>
    </div>
    <div class="card-container">
      <div class="cards">
        <div
          class="card"
          v-for="(image, index) in images"
          :key="index"
          @click="goToDetail(image)"
        >
          <img :src="image.modal_pic" alt="" class="img" />
          <div class="title">{{ image.title }}</div>
          <div class="filter"></div>
        </div>
      </div>
    </div>
    <Loader v-if="isLoad" />
  </div>
</template>

<script setup>
const { ref } = require("@vue/reactivity");
const { onMounted, computed } = require("@vue/runtime-core");
const { default: axios } = require("axios");
import { useStore } from "vuex";
import Detail from "../../components/detail/index.vue";
import Loader from "@/components/loader/index.vue";

//refs
const images = ref();
const isBasic = ref(true);
const filter = ref(null);
const store = useStore();
const image = ref("");
const isLoad = ref(false);

//mounts
onMounted(() => {
  getImag("basic_info");
});

//computed
const showDetail = computed(() => store.state.showDetail);
//methods
const getImag = (tab) => {
  isLoad.value = true;
  const wmsPath =
    location.origin.includes("a01-brandsite-h5.k8s-fat.com") ||
    location.origin.includes("localhost")
      ? "_t"
      : "_l";
  new Promise((resolve, reject) => {
    axios
      .get(
        `/cdn/1e3c3bMB/externals/static/_wms/${wmsPath}/_data/_promo/${tab}.txt?v=` +
          Math.floor(Date.now() / 1000)
      )
      .then((data) => {
        if (data.status) {
          isLoad.value = false;
        }
        images.value = data.data
          .map((item) => {
            const final_data = {
              title: item.title,
              sub_title: item.label,
              url:
                item.pic.replace("1e3c3bFM", "A01FM") +
                "?r=" +
                Math.floor(Date.now() / 1000),
              modal_pic: item.pic,
              date: item.time_begin,
              index: item.index,
              description: item.describe,
            };
            return final_data;
          })
          .sort((a, b) => a.index - b.index);
      });
  });
};
const changeTab = (tab, condition) => {
  isBasic.value = condition;
  getImag(tab);
};
const goToDetail = (img) => {
  image.value = img;
  store.commit("SET_SHOWDETAIL", true);
};
</script>

<style lang="scss" scoped>
.news-container {
  padding: 0 3vw;

  .btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3.5vh 0;
    .btn {
      margin: 0px 5px;
      border-radius: 40px;
      color: var(--gray);
      font-weight: 700;
      width: 125px;
      height: 35px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--font-large);
    }
    .active {
      background-color: #fff;
      color: var(--brown);
      box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.1);
      transition: 0.5s;
    }
    .notActive {
      transition: 0.5s;
      background-color: var(--bg-gray);
    }
  }
  .card-container {
    .cards {
      .card {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        .img {
          place-self: center;
          height: 150px;
          width: 100%;
          object-fit: cover;
          line-height: 0%;
          display: block;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        // .filter {
        //   border-top-left-radius: 5px;
        //   border-top-right-radius: 5px;
        //   transition: 0.3s;
        //   height: 150px;
        //   width: 94vw;
        //   position: absolute;
        //   content: "";
        //   background: #1b47a9;
        //   opacity: 0.4;
        //   z-index: 2;
        // }

        .title {
          padding: 15px;
          background: #fff;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          color: var(--brown);
          font-size: var(--font-large);
          font-weight: 700;
          box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
