/*
 * explain: 平台网关所有接口---具体接口说明请参考平台网关接口文档（新增接口时，请放入对应主题模块）
 * time： 2019/10/21
 * auth: jayce
 */
import request from '@/utils/request';
/********** 公共服务接口 **********/

/*
 * 获取在线客服跳转地址
 */
export function liveChatAddress(data) {
    return request({ url: '/liveChatAddress', method: 'post', data });
}
/*
 * 获取OCSS在线客服跳转地址
 */
export function liveChatAddressOCSS(data) {
    return request({ url: '/liveChatAddressOCSS', method: 'post', data });
}

export function webToken(data) {
    return request({ url: '/webToken', method: 'post', data });
}
