import axios from 'axios';
import md5 from 'md5';
import axiosConfig from '@/config/config';
import {store} from '@/store/index';

const isDev = process.env.NODE_ENV === 'development';
const baseURL = (isDev ? axiosConfig.apiUrl : '') + '/_glaxy_1e3c3b_';
const service = axios.create({
    baseURL: baseURL,
    timeout: 30 * 1000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
});

async function assembleParam(config) {
    const qid = getQid();
    let params = ''; //保留参数
    if (config.headers['Content-Type'] == 'multipart/form-data') {
        // FormData
        if (!config.data) config.data = new FormData();
        config.data.append('productId', axiosConfig.PRODUCT_ID);
        if (store.state.loginStatus && !config.data.get('loginName')) {
            config.data.append('loginName', store.state.userName);
        }
        // console.log('assembleParam FormData',config.data.get('loginName'));
        let form_data = {};
        for (let pair of config.data.entries()) {
            if (typeof pair[1] == 'string') {
                form_data[pair[0]] = pair[1];
            }
        }
        params = form_data;
    } else {
        // JSON
        config.data || (config.data = {});
        config.data['productId'] = axiosConfig.PRODUCT_ID;
        if (store.state.loginStatus && !config.data['loginName']) {
            config.data['loginName'] = store.state.userName;
        }
        params = config.data;
    }
    Object.assign(config, {
        headers: {
            deviceId: localStorage.getItem('deviceId'),
            v: axiosConfig.HEADER_V,
            appId: axiosConfig.APP_ID,
            token: store.state.token,
            qid: qid,
            sign: getSign(params, qid),
            domainName: location.hostname,
        },
    });
    /**
     * 如果没有token，则等待token获取成功再调取该接口
     * 调用vue生命周期控制 in /App.vue
     */
    // 设置部分传递到存款小助手参数
    // window.iframData = {};
    // window.iframData.token = store.state.token;
    // window.iframData.deviceId = store.state.deviceId;
    // window.iframData.appKey = axiosConfig.APP_ID;
    // window.iframData.platformVersion = axiosConfig.HEADER_V;
    return config;
}

service.interceptors.request.use(
    config => {
        return Promise.resolve(Object.assign(config, assembleParam(config)));
    },
    error => {
        Promise.reject(error);
    },
);

service.interceptors.response.use(
    response => {
        if (response.data.head.errCode == '0000') {
            return Promise.resolve({
                status: true,
                head: response.data.head,
                data: response.data.body, //兼容旧代码
                body: response.data.body,
            });
        } else {
            return Promise.resolve({
                status: false,
                head: response.data.head,
                data: response.data.head, //兼容旧代码
                body: response.data.body || '',
            });
        }
    },
    error => {
        return {
            status: false,
        };
    },
);

/**
 * qid: 唯一请求 ID, 建议生成规则 MD5(毫秒时间戳 + 随机 6 位数)
 */
function getQid() {
    // 获取随机生成的qid
    return md5(new Date().getTime() + (100000 + Math.floor(Math.random() * 900000)).toString());
}

/**
 * qid: 唯一请求 ID, 建议生成规则 MD5(毫秒时间戳 + 随机 6 位数)
 */
function getSign(data, qid) {
    // 获取加密签名
    return md5(
        JSON.stringify(data)
            .split('')
            .sort()
            .reverse()
            .join('') +
            qid +
            axiosConfig.APP_ID +
            axiosConfig.HEADER_V +
            (store.state.token ? store.state.token + store.state.u2token : ''),
    );
}

/**
 * head: 接口返回的含有错误信息的head
 */


export default service;
