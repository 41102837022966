<template>
  <div class="swiper-cont">
    <van-swipe>
      <van-swipe-item v-for="(item, index) in images" :key="index">
        <div class="card">
          <img :src="item.url" alt="" />
        </div>
      </van-swipe-item>
      <template #indicator="{ active, total }">
        <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
      </template>
    </van-swipe>
  </div>
</template>

<script setup>
const { ref } = require("@vue/reactivity");
const { onMounted } = require("@vue/runtime-core");
const { default: axios } = require("axios");

//refs
const images = ref();

//created
onMounted(() => {
  getImag();
});
//methods
const getImag = () => {
  const wmsPath =
    location.origin.includes("a01-brandsite-h5.k8s-fat.com") ||
    location.origin.includes("localhost")
      ? "_t"
      : "_l";
  new Promise((resolve, reject) => {
    axios
      .get(
        `/cdn/1e3c3bMB/externals/static/_wms/${wmsPath}/_data/_promo/manual_data.txt?v=` +
          Math.floor(Date.now() / 1000)
      )
      .then((data) => {
        images.value = data.data
          .map((item) => {
            const final_data = {
              title: item.title,
              sub_title: item.label,
              url:
                item.pic.replace("1e3c3bFM", "A01FM") +
                "?r=" +
                Math.floor(Date.now() / 1000),
              modal_pic: item.pic,
              index: item.index,
              description: item.describe,
            };
            return final_data;
          })
          .sort((a, b) => a.index - b.index);
      });
  });
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  img {
    width: 100%;
    height: 92vh;
    display: block;
    object-fit: cover;
  }
}
.custom-indicator {
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
}
</style>
