import { webToken, liveChatAddress } from "@/api/base";
import { liveChatAddressOCSS } from "@/api/base";
import business from "./business";
const Base64 = require("js-base64").Base64;

export default {
  getWebToken(store, data) {
    const info = localStorage.getItem("info")
      ? JSON.parse(localStorage.getItem("info"))
      : "";

    if (info && info.data) {
      // 如果活动页传来了数据
      if (!store.state.token) {
        // 如果网站没有token，需要初始化
        info.data.token && business.setWebSiteInitData(info.data); // 如果活动页传来了token,获取站点初始化数据
      }
      if (!store.state.loginStatus) {
        // 如果网站没有登录
        info.data.loginStatus &&
          business.setLoginData(
            Object.assign(info.data, { loginName: info.data.loginName })
          ); // 如果活动页传来了登录状态,获取通用登录后数据
      }
    } else {
      if (!store.state.token) {
        // 如果网站没有token，需要初始化
        webToken().then((res) => {
          if (res.status) {
            business.setWebSiteInitData(
              JSON.parse(Base64.decode(res.data.info))
            ); // 获取站点初始化数据
          }
        });
      }
    }
  },
  getLiveChatAddress(store, data) {
    // 这个是800客服，弃用中
    liveChatAddress(data).then((res) => {
      res.status && store.commit("SET_LIVECHATADDRESS", res.data);
    });
  },
  getLiveChatAddressOCSS(store, data) {
    // 执行OCSS设定
    const settingOCSSData = (propertyVal, targetUrl) => {
      // 指定预设url
      const defaultUrl =
        "https://ocss.why918.com/im/0xlixv.html?appType=4&domainName=" +
        location.host;
      const url = targetUrl ? targetUrl : defaultUrl;
      // 备份资料在vuex
      store.commit("SET_LIVECHATADDRESSOCSS", {
        propertyStr: propertyVal,
        url,
        state: true,
      });
      // 设定ocss用的参数在window.ocss
      business.setOCSSData(propertyVal, url);
    };
    // 一开始的url不行要尝试domianList
    const domianListTry = (originUrl, domainBakList, propertyVal) => {
      const urlObj = new URL(originUrl);
      Promise.any(
        domainBakList.map((domain) => {
          const url = domain + urlObj.port + urlObj.pathname + urlObj.search;
          return fetch(url);
        })
      )
        .then((res3) => {
          if (res3.ok) {
            settingOCSSData(propertyVal, res3.url);
          } else {
            settingOCSSData(propertyVal);
          }
        })
        .catch(() => settingOCSSData(propertyVal));
    };
    liveChatAddressOCSS(data).then((res) => {
      // OCSS有开启时，使用OCSS客服

      let propertyVal =
        res.data && res.data.propertyStr
          ? JSON.parse(res.data.propertyStr)
          : "";
      if (res.status && res.data.url) {
        // 先看url打不打的通

        fetch(res.data.url)
          .then((res2) => {
            if (res2.ok) {
              settingOCSSData(propertyVal, res.data.url);
            } else {
              // url打不通改尝试domainBakList里面的域名
              if (res.data.domainBakList && res.data.domainBakList.length) {
                domianListTry(
                  res.data.url,
                  res.data.domainBakList,
                  propertyVal
                );
              } else {
                settingOCSSData(propertyVal);
              }
            }
          })
          .catch((err) => {
            // url打不通改尝试domainBakList里面的域名
            if (res.data.domainBakList && res.data.domainBakList.length) {
              domianListTry(res.data.url, res.data.domainBakList, propertyVal);
            } else {
              settingOCSSData(propertyVal);
            }
          });
      } else {
        settingOCSSData(propertyVal);
      }
    });
  },
};
