import { createStore } from 'vuex'
import * as Cookies from "js-cookie";
import actions from "./actions";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
Cookies.set("userOnline", true);

export const store = createStore({
  state, 
  getters, 
  mutations, 
  actions
})
