import Util from "@/utils/util.js";
import {store} from "./index"

export default {
  setWebSiteInitData(data) {
    // 获取站点初始化数据
    const { token, u2token } = data;
    store.commit("SET_TOKEN", token);
    store.commit("SET_U2TOKEN", u2token);
    store.dispatch("getLiveChatAddressOCSS", { cancelLoading: true });
  },
  setOCSSData(loginData, url) {
    window.ocss = loginData;
    const ocssTag = document.getElementById("ocss_script");
    // 如果ocss_script已经存在，就不重复生成script
    if (ocssTag) return;
    Util.loadJS(url, true, "ocss_script");
  },
  reStoreOCSSscript() {
    if (store.state.token) {
      store.dispatch("getLiveChatAddressOCSS");
    }
  },
};
