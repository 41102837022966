<template>
  <div class="nav-container">
    <div class="logo">
      <img src="./imgs/logo(1).svg" alt="" @click="$router.push('/')" />
    </div>
    <div class="title">{{ $route.name }}</div>
    <div class="burger" @click="open" id="burger">
      <!-- <img src="./imgs/burger.svg" alt="" /> -->
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
  <transition name="fadeRight">
    <keep-alive>
      <FullNav
        @back="showFullNav = false"
        v-if="showFullNav"
        :isAnimate="isAnimate"
      />
    </keep-alive>
  </transition>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import FullNav from "./components/fullNav.vue";

//refs
const showFullNav = ref(false);

//methods
const open = () => {
  showFullNav.value = true;
};
</script>

<style lang="scss" scoped>
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  height: 8vh;
  padding: 10px;
  .logo {
    img {
      width: 90px;
    }
  }
  .title {
    color: var(--brown);
    font-size: var(--font-xlarge);
    font-weight: bold;
  }
  .burger {
    width: 100px;
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: flex-end;
    // img {
    //   width: 30px;
    // }

    .line {
      width: 22px;
      height: 3px;
      background: var(--brown);
      position: relative;
      border-radius: 100px;
      transition: 0.2s;
      &:nth-child(2) {
        margin: 5px 0;
        opacity: 1;
      }
      &::before {
        content: "";
        background: var(--brown);
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: -1px;
        border-radius: 100px;
        margin-left: -8px;
        opacity: 1;
      }
      .animateLine {
        &:nth-child(2) {
          opacity: 0;
        }
        &::before {
          opacity: 0;
        }
        &:last-child() {
          transform: translateY(-6px) rotate(135deg);
        }
        &:first-child() {
          transform: translateY(10px) rotate(45deg);
        }
      }
    }
  }
}
</style>
