<template>
  <NavBar />
  <div class="kefu" @click="goOnlineCS">
    <div class="icon">
      <img src="../assets/Images/kefu.svg" alt="" />
    </div>
  </div>
  <div class="section-container">
    <router-view />
  </div>
</template>

<script setup>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import NavBar from "../components/navbar/index.vue";

const store = useStore();
const showDetail = computed(() => store.state.showDetail);

const liveChatOCSSMode = ref(
  computed(() => store.state.liveChatAddressOCSS.state)
);

const goOnlineCS = () => {
  if (liveChatOCSSMode.value) {
    cskefu.openChatDialog();
  }
};
</script>

<style lang="scss">
@import url("../assets/sass/TPDefault.scss");
.kefu {
  top: 100px;
  z-index: 9;
  right: 3px;
  position: fixed;

  .icon {
    img {
      width: 65px;
    }
  }
}
</style>
